<template>
  <div
    ref="parent"
    data-test="search-by-addressmodal-additional"
    class="am-a white--bg relative"
  >
    <div class="am-a__wrap d-flex flex-column flex-grow">
      <div
        class="am-a__header d-flex align-center justify-between"
      >
        <d-btn
          data-test="back-button"
          icon
          color="custom"
          class="modal__back"
          @click="$emit('close')"
        >
          <img
            loading="lazy"
            src="@/assets/images/svg/arrow-right.svg"
            alt="arrow"
            style="transform: rotate(180deg)"
          >
        </d-btn>
        <button
          class="am-a__city relative"
          @click="showDialogCity"
        >
          {{
            currentCity?.short_name
              ? currentCity?.short_name + " "
              : ""
          }}{{ currentCity?.name }}
        </button>
      </div>
      <p
        v-if="title"
        class="headline-6 am-a__title"
      >
        {{ title }}
      </p>
      <form
        autocomplete="off"
        class="am-a__form d-flex relative"
        name="form"
      >
        <img
          v-if="street.trim() === itemTitle(currentCity)"
          loading="lazy"
          src="@/assets/images/svg/pin-thin_sec.svg"
          class="am-a__form-icon"
          alt="pin"
          style="width: 24px; height: 24px;"
        >
        <img
          v-else
          loading="lazy"
          src="@/assets/images/svg/search-thin.svg"
          class="d-flex am-a__form-icon"
          alt="search"
          style="width: 24px; height: 24px; opacity: .3"
        >
        <textarea
          id="additional-textarea"
          ref="saRef"
          :value="street"
          autocomplete="honorific-suffix"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          class="am-a__textarea flex-grow"
          maxlength="100"
          placeholder="Введите улицу и номер дома"
          rows="1"
          style="overflow-y: hidden"
          type="text"
          @blur="focusOff"
          @click="focusOn"
          @input="input"
          @keydown="keyDown"
        />
        <div class="am-a__actions d-flex align-center">
          <img
            v-show="street && street.trim() !== itemTitle(currentCity)"
            loading="lazy"
            src="@/assets/images/svg/chips-close_gray.svg"
            class="am-a__clear d-flex"
            alt="close"
            @click="clearStreet"
          >
          <div
            v-show="showSelectAddress"
            style="margin-left: 4px"
            class="d-flex am-a__confirm primary--bg"
            @click="selectAddress"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/arrow-right_white.svg"
              alt="arrow"
            >
          </div>
        </div>
      </form>
      <div
        v-if="loading"
        class="am-a__popper relative"
      >
        <div
          v-for="item in 20"
          :key="'load' + item"
          class="w-100 am-a__btn justify-center d-flex flex-column"
        >
          <VSkeleton
            style="width: 180px; min-height: 25px; max-height: 25px"
            :style="!selectedStreet ? 'max-width: 60%' : 'max-width: 60px'"
          />
          <VSkeleton
            v-if="!selectedStreet"
            style="
              width: 120px;
              min-height: 12px;
              max-height: 12px;
              max-width: 40%;
              margin-top: 4px;
            "
          />
        </div>
      </div>
      <div
        v-else-if="
          wStreets
            && (!street
              || street.trim() === itemTitle(selectedCity)
              || street.trim() === itemTitle(currentCity)
              || (!responseList?.address_objects?.length
                && !responseList?.houses?.length))
        "
        class="am-a__tips hidden d-flex flex-column"
      >
        <div class="am-a__streets gray-p--bg d-flex flex-column">
          <p class="title-5 gray-dark--text">
            Или выберите из списка
          </p>
          <div class="am-a__streets-wrap">
            <div
              v-if="loadingStreets"
              class="d-flex flex-wrap"
            >
              <div
                v-for="item in 5"
                :key="item + 'streetSkeleton'"
                class="d-flex am-a__streets-btn"
                style="width: 120px; height: 25px; padding: 0"
              >
                <VSkeleton
                  class=""
                  style="width: 120px; min-height: 25px; max-height: 25px"
                />
              </div>
            </div>
            <template v-else>
              <button
                v-for="street in popularStreets"
                :key="street.fias_id"
                class="am-a__streets-btn white--bg title-5"
                @click="selectStreet(street)"
              >
                {{ itemTitle(street) }}
              </button>
            </template>
          </div>
          <button
            class="am-a__streets-more black--text d-flex align-center title-5"
            @click="showModalStreets = true"
          >
            Показать все улицы
            <img
              style="width: 24px; height: 24px; margin-bottom: -3px; margin-left: 4px"
              loading="lazy"
              src="@/assets/images/svg/arrow-expand.svg"
              alt="arrow"
            >
          </button>
        </div>
        <div
          v-if="recentSearchShow?.length"
          class="am-a__recent hidden d-flex flex-column"
        >
          <div class="d-flex align-center justify-between">
            <p>Вы искали:</p>
            <button
              class="am-a__recent-clear gray-dark--text d-flex align-center"
              @click="clearRecentSearch"
            >
              <span class="mask-icon mask-size-lg mask-trash" />
              Удалить все
            </button>
          </div>
          <div class="am-a__recent-wrap">
            <template v-if="newWindow">
              <a
                v-for="(item, index) in recentSearchShow.filter(
                  (el, i) => i < 5,
                )"
                :key="item.houseUrl + index"
                :href="`/personal/address/${item.houseUrl}/`"
                target="_blank"
                class="am-a__btn justify-center d-flex flex-column"
                @click="chooseRecentHouse(item)"
              >
                <span class="relative d-flex align-center">
                  <img
                    loading="lazy"
                    src="@/assets/images/svg/search-thin.svg"
                    class="d-flex am-a__btn-icon"
                    alt="search"
                    style="width: 20px; height: 20px; opacity: .3"
                  >
                  <span class="am-a__btn-title secondary-dark--text">{{
                    item.name
                  }}</span>
                </span>
                <small
                  v-if="item.parents_tooltip"
                  class="am-a__btn-subtitle gray-dark--text subhead-6"
                >{{ item.parents_tooltip }}</small>
              </a>
            </template>
            <template v-else>
              <button
                v-for="(item, index) in recentSearchShow.filter(
                  (el, i) => i < 5,
                )"
                :key="item.houseUrl + index"
                class="am-a__btn justify-center d-flex flex-column"
                @click="chooseRecentHouse(item)"
              >
                <span class="relative d-flex align-center">
                  <img
                    loading="lazy"
                    src="@/assets/images/svg/search-thin.svg"
                    class="d-flex am-a__btn-icon"
                    alt="search"
                    style="width: 20px; height: 20px; opacity: .3"
                  >
                  <span class="am-a__btn-title secondary-dark--text">{{
                    item.name
                  }}</span>
                </span>
                <small
                  v-if="item.parents_tooltip"
                  class="am-a__btn-subtitle gray-dark--text subhead-6"
                >{{ item.parents_tooltip }}</small>
              </button>
            </template>
          </div>
        </div>
      </div>
      <div
        v-else-if="popperShow"
        ref="dragPopper"
        :class="{ 'am-a__popper-active': popperShow }"
        class="am-a__popper relative"
        @scroll="drag"
      >
        <template v-if="newWindow">
          <a
            v-for="(item, index) in responseList.houses"
            :key="item.id + index"
            :href="`/personal/address/${item.autogenerated_url}/`"
            target="_blank"
            class="w-100 am-a__btn justify-center d-flex flex-column"
            @click.stop="chooseHouse(item)"
          >
            <span class="relative d-flex align-center">
              <img
                loading="lazy"
                src="@/assets/images/svg/search-thin.svg"
                class="d-flex am-a__btn-icon"
                alt="search"
                style="width: 20px; height: 20px; opacity: .3"
              >
              <span
                class="am-a__btn-title"
                v-html="addressWithStrong(item.number)"
              />
            </span>
            <small
              v-if="item.parents_tooltip"
              class="am-a__btn-subtitle gray-dark--text subhead-6"
            >{{ item.parents_tooltip }}</small>
          </a>
        </template>
        <template v-else>
          <button
            v-for="(item, index) in responseList.houses"
            :key="item.id + index"
            class="w-100 am-a__btn justify-center d-flex flex-column"
            @click.stop.prevent="chooseHouse(item)"
          >
            <span class="relative d-flex align-center">
              <img
                loading="lazy"
                src="@/assets/images/svg/search-thin.svg"
                class="d-flex am-a__btn-icon"
                alt="search"
                style="width: 20px; height: 20px; opacity: .3"
              >
              <span
                class="am-a__btn-title"
                v-html="addressWithStrong(item.number)"
              />
            </span>
            <small
              v-if="item.parents_tooltip"
              class="am-a__btn-subtitle gray-dark--text subhead-6"
            >{{ item.parents_tooltip }}</small>
          </button>
        </template>
        <button
          v-for="(item, index) in responseList.address_objects"
          :key="item.fias_id + index"
          class="w-100 am-a__btn justify-center d-flex flex-column"
          @click.stop="chooseAddress(item)"
        >
          <span
            class="am-a__btn-title"
            v-html="addressWithStrong(itemTitle(item))"
          />
          <small
            v-if="item.parents_tooltip"
            class="am-a__btn-subtitle gray-dark--text subhead-6"
          >{{ item.parents_tooltip }}</small>
        </button>
        <div
          v-if="loadingMoreSkeleton"
          class="w-100 am-a__btn justify-center d-flex flex-column"
        >
          <VSkeleton
            style="width: 180px; min-height: 25px; max-height: 25px"
            :style="!selectedStreet ? 'max-width: 60%' : 'max-width: 60px'"
          />
          <VSkeleton
            v-if="!selectedStreet"
            style="
              width: 120px;
              min-height: 12px;
              max-height: 12px;
              max-width: 40%;
              margin-top: 4px;
            "
          />
        </div>
        <div
          v-show="isError"
          class="am-a__error d-flex flex-column justify-center align-center flex-grow"
          @click.stop
        >
          <p>К сожалению, мы не можем найти этот адрес</p>
          <span>Попробуйте изменить написание</span>
        </div>
        <p
          v-if="
            !isError
              && (enableInfinite === false
                || (selectedStreet
                  ? responseList.houses.length >= totalItems
                  : responseList.address_objects.length >= totalItems))
          "
          class="text-center"
          style="padding: 20px 0"
          @click.stop
        >
          Продолжите ввод, если не нашли в этом списке
        </p>
      </div>
    </div>

    <LazyVDialog
      v-model="dialogCity"
      :fullscreen="!disabledPortal"
      :width="disabledPortal ? 'auto' : ''"
    >
      <LazyModalCityCheck
        @close="dialogCity = false"
        @changed-city="changedCity"
      />
    </LazyVDialog>
    <LazyVDialog
      v-model="showModalStreets"
      :fullscreen="!disabledPortal"
      :width="disabledPortal ? 'auto' : ''"
    >
      <LazyModalStreetsSelect
        @close="showModalStreets = false"
        @select-street="selectStreet"
      />
    </LazyVDialog>
  </div>
</template>

<script lang="ts" setup>
import type { BaseAddressObject, House } from '~/api'
import { useCities } from '~/store/cites/cities.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { debounce } from '~/helpers'
import { useMainStore } from '~/store/main/main.store'
import { recentSearchFn } from '~/composible/recentSearch'

import { getAddresLocation } from '~/composible/getAddresLoc'

const props = defineProps({
  target: String,
  title: {
    type: String,
  },
  wFocus: {
    type: Boolean,
  },
  newWindow: {
    type: Boolean,
  },
  wStreets: {
    type: Boolean,
  },
  woFocus: {
    type: Boolean,
  },
  redirect: {
    type: Boolean,
    default: true,
  },
  reOpenCity: Boolean,
})
const emit = defineEmits(['close', 'closeAll', 'reOpen'])

const $route = useRoute()
const $router = useRouter()
const ctx = useNuxtApp()
const mainStore = useMainStore()
const cityStore = useCities()
const tariffsStore = useTariffs()
const parent = ref()
const street = ref('')
const saRef = ref()
const dialogCity = ref(false)
const selectedHouse = ref<House>()
const selectedStreet = ref<BaseAddressObject>()
const popularStreets = ref()
const selectedCity = ref()
const getAddressText = computed(() => cityStore.getAddressText)
const disabledMobile = computed(() => mainStore.disabledMobile)
const disabledPortal = computed(() => mainStore.disabledPortal)
const oldClientCookie = useCookie('oldClient', { maxAge: 60 * 60 * 24 })
const providerClientCookie = useCookie('providerClient', { maxAge: 60 * 60 * 24 })
const showDialogCity = () => {
  if (props.reOpenCity) {
    emit('reOpen')
    return
  }
  dialogCity.value = true
}
const domain = computed(() => mainStore.getDomain)
const enableInfinite = ref<boolean | undefined>()
const houseEmpty = ref(false)
const totalItems = ref<number | undefined>()
const popperShow = ref(false)
const eventInput = ref(true)
const loading = ref(false)
const loadingStreets = ref(false)
const loadingValidate = ref()
const loadingMore = ref(false)
const needFocus = ref(false)
const loadingMoreSkeleton = ref(false)
const showModalStreets = ref(false)
const dragPopper = ref()
const isError = ref(false)
const focused = ref(false)
const currentCity = computed(() => cityStore.getCity)
const currentStreet = computed(() => cityStore.getDataStreet)
const houseUrl = computed(() => cityStore.getHouseUrl)
const recentSearch = computed(() => tariffsStore.getRecentSearch)
const recentSearchShow = computed(() =>
  recentSearch.value.filter(el => el.houseUrl !== houseUrl.value),
)
const isSearchAddressPage = computed(
  () =>
    $route.name === 'personal-address-slug'
    || $route.name === 'city-streets-street'
    || $route.name === 'city-address-slug',
)
const partner = computed(() => mainStore.getDomainConfig)
const log = ref([])
const { clearRecentSearch, addRecentSearch } = recentSearchFn()

const responseList = ref({
  address_objects: [] as BaseAddressObject[],
  houses: [] as House[],
})
const changedCity = () => {
  dialogCity.value = false
  if (needFocus.value || props.wFocus) {
    if (saRef.value) saRef.value.focus()
  }
}

const selectStreet = async (street) => {
  needFocus.value = false
  chooseAddress(street)
  showModalStreets.value = false
}

const getPopularStreets = async () => {
  loadingStreets.value = true
  try {
    popularStreets.value = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/children/`, {
      method: 'GET',

      params: {
        fias_id: currentCity.value?.fias_id,
        city_type: 'all',
        sort: 'POPULATION',
        level: 'street',
        limit: 10,
        with_available_smart_filters: false,
        with_provider_slugs: false,
        optimized: true,
        in_sitemap: undefined,
        only_with_houses: true,
      },
    })
  }
  catch (e) {
    console.log(e)
  }
  loadingStreets.value = false
}

const clearAddress = () => {
  let res

  if ($route.name === 'city-not-available') {
    $router.push({ path: `/${currentCity.value?.url}/` })
    return
  }

  const redirect = isSearchAddressPage.value

  nextTick(async () => {
    try {
      res = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${currentCity.value?.fias_id}/ancestors/`, {
        method: 'GET',

        params: {
          include_self: true,
          morph: true,
        },
      })
    }
    catch {}

    cityStore.$patch({
      addressAncestors: res,
    })
    cityStore.house = {}
    tariffsStore.passThroughProviderId = null

    if (redirect) {
      $router.push({ path: `/${currentCity.value?.url}/` })
    }

    const coockieAddress = useCookie('currentAddress')
    coockieAddress.value = ''
  })
}
const chooseRecentHouse = (st) => {
  selectedHouse.value = st.selectedHouse
  nextTick(() => {
    street.value = st.name
    houseEmpty.value = false
  })
  changeAddress()
  if (props.redirect) emit('close')
}

const searchAddress = (queryString: string) => {
  if (!(selectedCity.value && queryString.includes(selectedCity.value.name))) {
    selectedCity.value = undefined
  }
  if (
    !(selectedStreet.value && queryString.includes(selectedStreet.value.name))
  ) {
    houseEmpty.value = false
    selectedStreet.value = undefined
    selectedHouse.value = undefined
  }

  if (
    !(selectedHouse.value && queryString.includes(selectedHouse.value.number))
  ) {
    selectedHouse.value = undefined
  }

  if (street.value)
    getSuggestionsMulti(
      queryString
        .replace(itemTitle(selectedCity.value), '')
        .replace(itemTitle(currentCity.value), '')
        .replace(itemTitle(selectedStreet.value), '')
        .trim(),
      (cb) => {
        responseList.value
          = typeof cb === 'object' && !Array.isArray(cb) && cb !== null
            ? cb
            : {
                address_objects: [],
                houses: [],
              }
      },
    )
}

const getSuggestionsMulti = async (
  queryString: string,
  cb: (arg) => void,
  offset?: number,
) => {
  loadingMore.value = true
  try {
    const validate = Math.random()
    loadingValidate.value = validate
    const response = await $fetch(`https://${mainStore.domain}/api/suggestions/multi/`, {
      method: 'GET',

      params: {
        query: queryString,
        tooltips_always: true,
        site_city_fias_id: currentCity.value?.fias_id,
        street_fias_id: selectedStreet.value?.fias_id,
        move_house_street_tooltip: true,
        limit: 20,
        offset,
      },
    })
    log.value.push({
      req: queryString,
      resp: response,
    })
    if (loadingValidate.value === validate) {
      enableInfinite.value = response.can_scroll
      totalItems.value = response.total_items
      const block = document.getElementsByClassName('am-a__popper')?.[0]
      if (block && !offset) block.scrollTo(0, 0)
      cb(response)
      popperShow.value = true
      loading.value = false
      loadingMoreSkeleton.value = false
      loadingMore.value = false
      if (response?.address_objects?.length || response?.houses?.length)
        isError.value = false
      else isError.value = true
    }
  }
  catch (e) {
    isError.value = true
    loadingMore.value = false
    // eslint-disable-next-line standard/no-callback-literal
    cb([])
  }
}

const debounceSearch = debounce(() => {
  searchAddress(street.value)
}, 350)

const textareaHeight = () => {
  saRef.value.style.height = 'auto'
  saRef.value.style.height
    = (+saRef.value.scrollHeight > 72 ? 72 : +saRef.value.scrollHeight) + 'px'
  saRef.value.style.overflowY
    = +saRef.value.scrollHeight > 72 ? 'scroll' : 'hidden'
}

const input = (event) => {
  if (eventInput.value) {
    eventInput.value = false
    ctx.$event('gtmSendEvent', {
      event: 'trackEvent',
      category: 'address',
      action: 'typing',
      label: '',
    })
  }
  needFocus.value = true
  loadingValidate.value = Math.random()
  event.target.composing = false
  street.value = event.target.value
  nextTick(() => {
    if (street.value) loading.value = true
    else {
      loading.value = false
      popperShow.value = false
    }
    debounceSearch()
    textareaHeight()
  })
}

const keyDown = (e) => {
  if (e.keyCode === 13) e.preventDefault()
}

const showSelectAddress = computed(() => {
  return (
    houseUrl.value
    && street.value.toLowerCase().trim()
    === getAddressText.value.houseAddress.toLowerCase().trim()
  )
})

const selectAddress = () => {
  $router.push({
    path: `/personal/address/${houseUrl.value}/`,
  })
  emit('closeAll')
}

const clearStreet = () => {
  street.value = ''
  houseEmpty.value = false
  popperShow.value = false
  selectedStreet.value = undefined
  selectedCity.value = undefined
  selectedHouse.value = undefined
  nextTick(() => {
    clearAddress()
    if (needFocus.value) {
      if (saRef.value) saRef.value.focus()
    }
    textareaHeight()
  })
}

const chooseAddress = (st) => {
  const oldCitySlug = currentCity.value?.url
  const redirect = isSearchAddressPage.value
  if (st.level === 'city' || st.fias_id === currentCity.value?.fias_id) {
    selectedCity.value = st
    nextTick(async () => {
      street.value = itemTitle(st)
      selectedHouse.value = undefined
      selectedStreet.value = undefined
      focusHome()
      let res
      try {
        res = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${st.fias_id}/ancestors/`, {
          method: 'GET',

          params: {
            include_self: true,
            morph: true,
          },
        })
      }
      catch (e) {
      }
      finally {
      }
      cityStore.$patch({
        addressAncestors: res,
      })
      cityStore.house = {}
      tariffsStore.passThroughProviderId = null

      loading.value = false

      const coockieCity = useCookie('currentCity')
      const coockieAddress = useCookie('currentAddress')
      coockieCity.value = selectedCity.value.fias_id
      coockieAddress.value = ''

      if (redirect) {
        $router.replace(`/${st.url}/providers-to-address/`)
      }
      else {
        $router.replace($route.path.replace(oldCitySlug!, st.url!))
      }
    })
  }
  else {
    selectedStreet.value = st

    nextTick(() => {
      street.value
        = (selectedCity.value
          ? itemTitle(selectedCity.value) + ' '
          : disabledMobile.value
            ? itemTitle(currentCity.value) + ' '
            : '') + itemTitle(st)
      selectedHouse.value = undefined
      searchAddress(street.value)
      if (!props.woFocus) focusHome()
    })
  }
  loading.value = true
}

const chooseHouse = (st) => {
  selectedHouse.value = st
  nextTick(() => {
    street.value = st.parents_tooltip
      ? st.parents_tooltip + ' ' + st.number
      : (selectedCity.value ? itemTitle(selectedCity.value) + ' ' : '')
        + itemTitle(selectedStreet.value)
        + ' '
        + st.number
    houseEmpty.value = false
  })
  changeAddress()
  if (props.redirect) emit('closeAll')
}
const changeAddress = () => {
  const oldCitySlug = currentCity.value?.url
  ctx.$event('gtmSendEvent', {
    event: 'trackEvent',
    category: 'address',
    action: 'confirmed',
    label: '',
  })
  nextTick(async () => {
    log.value = []
    let res
    let loc
    try {
      const { getAddresLoc } = getAddresLocation()

      const promiseLoc = async () => {
        loc = await getAddresLoc({
          url: selectedHouse.value!.autogenerated_url,
          houseId: selectedHouse.value!.id,
          houseFiasId: selectedHouse.value!.dadata?.fias_id,
          streetFiasId:
            selectedHouse.value!.dadata?.house
            && selectedHouse.value!.dadata?.parent_fias_id
              ? selectedHouse.value!.dadata?.parent_fias_id
              : undefined,
          houseNumber:
            selectedHouse.value!.dadata?.house
            && selectedHouse.value!.dadata?.parent_fias_id
              ? selectedHouse.value!.dadata?.house
              + (selectedHouse.value!.dadata?.block_type
                ? ' ' + selectedHouse.value!.dadata?.block_type
                : '')
              + (selectedHouse.value!.dadata?.block
                ? ' ' + selectedHouse.value!.dadata?.block
                : '')
              : undefined,
        })
      }
      const promiseRes = async () => {
        if (selectedHouse.value!.dadata?.parent_fias_id) {
          res = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${selectedHouse.value!.dadata?.parent_fias_id}/ancestors/`, {
            method: 'GET',

            params: {
              include_self: true,
              morph: true,
            },
          })
        }
      }

      if (props.redirect && !partner.value && !props.newWindow) {
        mainStore.$patch({
          showLoader: true,
        })
      }
      await promiseLoc()
      await promiseRes()
    }
    catch (e) {
      ctx.$sentryCaptureMessage(e)
      // result = []
      // showLoader: false
    }
    finally {
      selectedCity.value = res?.address_objects?.find(
        el => el.level === 'city',
      )
      if (!selectedCity.value)
        selectedCity.value = res?.address_objects?.find(
          el => el.level === 'street',
        )
      else
        selectedStreet.value = res?.address_objects?.find(
          el => el.level === 'street',
        )
      cityStore.$patch({
        addressAncestors: res,
      })
      mainStore.$patch({
        wrongAddress: false,
      })

      addRecentSearch({
        name: street.value,
        houseUrl: loc.autoGeneratedUrl,
        parents_tooltip:
          (selectedCity.value?.short_name
            ? selectedCity.value.short_name + ' '
            : '') + selectedCity.value?.name,
        selectedHouse: selectedHouse.value,
      })

      const coockieAddress = useCookie('currentAddress')

      coockieAddress.value = selectedHouse.value!.autogenerated_url
      mainStore.$patch({
        oldClient: false,
        providerClient: undefined,
      })
      oldClientCookie.value = null
      providerClientCookie.value = null

      if (selectedCity.value) {
        const coockieCity = useCookie('currentCity')
        coockieCity.value = selectedCity.value.fias_id
      }

      if (
        props.redirect
        && (!partner.value || $route.name === 'personal-address-slug')
      ) {
        if (props.newWindow) {
        }
        else {
          $router.push({
            path: `/personal/address/${loc.autoGeneratedUrl}/`,
          })
        }
      }
      else if (!partner.value && props.redirect && selectedCity.value) {
        $router.replace({
          path: $route.fullPath.replace(oldCitySlug, currentCity.value?.url),
        })
      }
      else if (!partner.value) {
        if (!props.redirect) emit('closeAll')
      }
      else {
        if (selectedCity.value)
          $router.replace({
            path: $route.fullPath.replace(oldCitySlug, currentCity.value?.url),
          })
        // const element = document.getElementsByClassName('layout')[0]
        // scrollToBlock(element, 500)
        if (!props.redirect) emit('closeAll')
      }
    }
  })
}

const itemTitle = (city: BaseAddressObject | undefined) => {
  return city
    ? `${city.short_name ? city.short_name + ' ' : ''}${city.name}`
    : ''
}

const addressWithStrong = (title: string) => {
  if (!street.value) return title
  const words = street.value.trim().split(/[^0-9а-яА-ЯёЁ]+/gmi).filter(Boolean).sort((a, b) => b.length - a.length)
  let newTitle = title
  const regexList = words.map(word => new RegExp(`(?!<strong[^>]*?>)(${word})(?![^<]*?</strong>)`, 'giu'))

  regexList.forEach((regex) => {
    newTitle = newTitle.replace(regex, match => `<strong class='cl-pr--t'>${match}</strong>`)
  })
  return newTitle
}

const focusHome = () => {
  nextTick(() => {
    street.value += ' '
    if (needFocus.value) {
      if (saRef.value) saRef.value.focus()
    }
    responseList.value = {
      address_objects: [],
      houses: [],
    }
  })
}

const drag = debounce(() => {
  if (
    !loadingMore.value
    && enableInfinite.value
    && (selectedStreet.value
      ? responseList.value?.houses?.length < totalItems.value
      : responseList.value?.address_objects?.length < totalItems.value)
  ) {
    if (dragPopper.value) {
      if (dragPopper.value.scrollHeight > dragPopper.value.clientHeight) {
        const scrolledToBottom
          = dragPopper.value.scrollHeight - dragPopper.value.clientHeight
          <= dragPopper.value.scrollTop + 300
        if (scrolledToBottom) loadMore()
      }
    }
  }
}, 50)

const loadMore = () => {
  if (street.value) {
    loadingMoreSkeleton.value = true
    getSuggestionsMulti(
      street.value
        .replace(itemTitle(selectedCity.value), '')
        .replace(itemTitle(currentCity.value), '')
        .replace(itemTitle(selectedStreet.value), '')
        .trim(),
      addMore,
      selectedStreet.value
        ? responseList.value?.houses?.length
        : responseList.value?.address_objects?.length,
    )
  }
}
const addMore = (val) => {
  responseList.value.houses.push(...val.houses)
  responseList.value.address_objects.push(...val.address_objects)
}

const focusOn = () => {
  focused.value = true
}
const focusOff = () => {
  if (!(document.activeElement?.localName === 'input')) focused.value = false
}
const scrollTopFn = () => {
  parent.value.scrollTo(0, 0)
  window.removeEventListener('resize', scrollTopFn)
}
onBeforeMount(() => {
  if (currentCity.value?.fias_id) getPopularStreets()
  nextTick(() => {
    if (!street.value && houseUrl.value) {
      street.value = getAddressText.value.houseAddress
    }
    else if (!street.value && currentStreet.value?.url) {
      chooseAddress(currentStreet.value)
    }
  })
})
onMounted(() => {
  if (props.wFocus) {
    setTimeout(() => {
      nextTick(() => {
        if (saRef.value) saRef.value.focus()
      })
    }, 10)
  }
  nextTick(() => parent.value.scrollTo(0, 0))
  if (ctx.$device.isAndroid) {
    window.addEventListener('resize', scrollTopFn)
  }
})

onBeforeUnmount(async () => {
  if (ctx.$device.isAndroid) {
    window.removeEventListener('resize', scrollTopFn)
  }
  if (log.value.length) {
    await $fetch(`https://${mainStore.domain}/api/core/logs/`, {
      method: 'POST',

      body: {
        name: 'address_fail',
        at_url: 'https://' + domain.value + $route.fullPath,
        level: 'DEBUG',
        // "data": JSON.stringify(log.value)
        data: log.value,
      },
    })
  }
})

defineExpose({ chooseAddress })

watch(
  () => currentCity.value?.fias_id,
  () => {
    getPopularStreets()
    street.value = ''
    responseList.value = {
      address_objects: [],
      houses: [],
    }
  },
)
</script>

<style scoped lang="scss">
.am-a {
  width: 780px;
  max-width: 100%;
  height: 561px;
  max-height: 100vh;
  display: block;
  padding: 40px 40px 8px;
  border-radius: 16px;

  @media (max-width: getBreakpoint(tablet)) {
    padding: 8px 16px 0;
    border-radius: 0;
  }
  .mask-trash {
    mask-image: url('@/assets/images/svg/trash.svg');
  }

  &__wrap {
    max-height: 100%;
  }
  &__header {
    margin-bottom: 8px;
  }

  &__back {
    padding: 0;
    width: 32px;
    height: 32px;
    @media (max-width: getBreakpoint(mobile-lg)) {
      width: 28px;
      height: 28px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      width: 24px;
      height: 24px;
    }
    &:deep(svg) {
      width: 32px;
      height: 32px;
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 28px;
        height: 28px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__textarea {
    padding: 12px 48px 12px 40px;
    border-radius: 24px;
    resize: none;
    background: color(gray-light-3);
    border: 1px solid color(gray-2);
    box-shadow: unset;
    outline: none;
    transition: border-color 0.3s;
    line-height: 1.5;
    min-height: 48px;
    @media (max-width: getBreakpoint(mobile-md)) {
      padding: 8px 40px 8px 36px;
      border-radius: 20px;
      font-size: fontSize(paragraph);
      min-height: 40px;
    }

    &:focus {
      border-color: color(secondary-dark);
    }

    &::placeholder {
      color: color(gray-dark);
    }
  }
  &__actions {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__clear {
    margin-right: 8px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    @media (max-width: getBreakpoint(mobile-md)) {
      right: 11px;
    }
  }

  &__confirm {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 4px;
    @media (max-width: getBreakpoint(mobile-lg)) {
      width: 34px;
      height: 34px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      width: 32px;
      height: 32px;
    }
    &:deep(svg) {
      width: 28px;
      height: 28px;
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 26px;
        height: 26px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__title {
    max-width: 628px;
    margin-bottom: 24px;
    padding-right: 28px;
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-bottom: 16px;
    }
  }

  &__form {
    margin-bottom: 20px;

    &-icon {
      position: absolute;
      left: 12px;
      top: 12px;
      @media (max-width: getBreakpoint(mobile-md)) {
        left: 10px;
        top: 8px;
      }
    }
  }
  &__tips {
    margin-top: 12px;
  }

  &__city {
    margin-left: 28px;
    color: color(secondary-dark);
    transition: color 0.3s;
    @media (max-width: getBreakpoint(mobile-lg)) {
      font-size: fontSize(paragraph);
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-left: 24px;
    }

    &-icon {
      position: absolute;
      left: -28px;
      top: 50%;
      transform: translateY(-50%);

      &:deep(svg) {
        path {
          fill: color(secondary-dark);
          transition: fill 0.3s;
        }
      }
    }

    &:hover {
      color: color(primary);

      .am-a__city-icon:deep(svg) {
        path {
          fill: color(primary);
        }
      }
    }

    & ~ & {
      margin-top: 12px;
    }
  }

  &__streets {
    margin-bottom: 24px;
    padding: 16px 16px 12px;
    border-radius: 16px;
    &-wrap {
      margin-top: 12px;
      max-height: 86px;
      overflow: hidden;
      margin-bottom: 16px;
      @media (max-width: getBreakpoint(tablet)) {
        max-height: 100px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        max-height: 96px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        max-height: 90px;
      }
    }
    &-btn {
      margin-right: 8px;
      margin-bottom: 8px;
      padding: 8px;
      border-radius: 4px;
      @media (max-width: getBreakpoint(tablet)) {
        padding: 4px 8px;
        margin-bottom: 4px;
      }
    }
    &-more {
      margin-left: auto;
      transition: color 0.24s;
      &:hover {
        color: color(primary);
      }
    }
  }
  &__recent {
    &-wrap {
      overflow-y: auto;
    }
    .am-a__btn {
      padding-left: 32px;
      border-bottom: none;
      min-height: 39px;
      height: 39px;
    }

    &-clear {
      transition: color 0.24s;
      font-size: fontSize(paragraph);
      @media (max-width: getBreakpoint(tablet)) {
        font-size: fontSize(label);
      }
      .mask-icon {
        margin-right: 8px;
      }
      &:hover {
        color: color(black);
      }
    }
  }

  &__popper {
    padding-left: 28px;
    flex: 1 1 auto;
    overflow-y: auto;
    -webkit-user-drag: none;
    @media (max-width: getBreakpoint(mobile-md)) {
      padding-left: 24px;
    }
  }

  &__btn {
    border-bottom: 1px solid color(gray-light);
    height: 57px;
    min-height: 57px;
    color: color(black);
    .secondary-dark--text {
      transition: color 0.24s;
    }
    &:hover .secondary-dark--text {
      color: color(primary);
    }

    &-title:deep(strong) {
      color: color(primary);
      font-weight: 400;
    }

    &-icon {
      position: absolute;
      left: -28px;
      top: 50%;
      transform: translateY(-50%);
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 53px;
      min-height: 53px;

      &-title {
        font-size: fontSize(paragraph);
      }

      &-subtitle {
        font-size: fontSize(small);
      }

      &-icon {
        left: -24px;
      }
    }
  }

  &__loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 1px;
    bottom: 0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    &:deep(svg) {
      will-change: transform;

      path:not(:nth-child(3)) {
        transform-origin: center;
        animation-name: path-animation;
        animation-iteration-count: infinite;
        // animation-direction: alternate;
        animation-duration: 1s;
        transform: scale(0);
      }

      path:nth-child(1) {
        animation-delay: 0.5s;
      }
    }
  }

  &__error {
    p {
      margin-bottom: 8px;
    }

    span {
      font-size: fontSize(paragraph);
    }
  }
}
</style>
