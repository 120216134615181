import { ofetch } from 'ofetch'

export default defineNuxtPlugin(() => {
  const {
    'x-real-ip': xRealIp,
    host,
    'x-utm-state': xUtm,
    cookie: ssrCookie,
  } = useRequestHeaders()
  const sessionidCookie = useCookie('sessionid')
  let defaultHeaders = {}
  if (sessionidCookie.value) {
    defaultHeaders = {
      'ip': xRealIp || null,
      'X-Sessionid': sessionidCookie.value,
      'X-Forwarded-Host': host,
      'X-Utm-State': xUtm,
      'cookie': ssrCookie,
    }
  }
  else {
    defaultHeaders = {
      'ip': xRealIp || null,
      'X-Forwarded-Host': host,
      'X-Utm-State': xUtm,
      'cookie': ssrCookie,
    }
  }
  if (import.meta.client) {
    const exclude = ['cookie', 'X-Utm-State', 'X-Forwarded-Host', 'ip']
    exclude.forEach((key) => {
      delete defaultHeaders[key]
    })
  }
  globalThis.$fetch = ofetch.create({
    headers: defaultHeaders,
  })
})
