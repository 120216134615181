<template>
  <div>
    <div
      class="provider-review"
      data-test="middle-review-card"
    >
      <header class="provider-review__header">
        <div class="provider-review__avatar">
          {{ review.authorName[0].toUpperCase() }}
        </div>
        <div class="provider-review__name">
          <p>
            {{ review.authorName
            }}<span>{{
              new Date(review.created).toLocaleString("ru-RU", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              })
            }}</span>
          </p>
          <DCustomRate
            color="#EE3C6B"
            :value="review.ratingFinal"
          />
        </div>
      </header>
      <p class="provider-review__text">
        {{ review.text }}
      </p>
      <div class="provider-review__footer">
        <div class="provider-review__likes d-flex align-center">
          <button
            :class="{ active: review.currentUserReaction === true }"
            @click="setLike(true)"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/like.svg"
              alt="like"
              class="d-flex"
            >
          </button>
          <span class="subhead-3">{{ review.likes }}</span>
          <button
            :class="{ active: review.currentUserReaction === false }"
            @click="setLike(false)"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/dislike.svg"
              alt="like"
              class="d-flex"
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useMainStore } from '~/store/main/main.store'

export default defineComponent({
  name: 'ProviderPageReviewCard',
  props: {
    review: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const mainStore = useMainStore()

    const setLike = async (val) => {
      try {
        await $fetch(`https://${mainStore.domain}/api/reviews/${props.review.id}/react/`, {
          method: 'POST',

          body: {
            like: val,
          },
        })
      }
      catch {}

      emit('updateReviewsList')
    }

    return {
      setLike,
    }
  },
})
</script>

<style scoped lang="scss">
.provider-review {
  background: color(white);
  border: 1px solid color(gray-light);
  box-shadow: 4px 4px 30px rgba(3, 5, 32, 0.04);
  border-radius: 16px;
  padding: 24px 16px;
  width: 341px;
  max-width: calc(100vw - 48px);

  &__header {
    display: flex;
    margin-bottom: 24px;
  }

  &__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: fontSize(headline-7);
    font-weight: 500;
    line-height: 28px;
    margin-right: 8px;
    background: color(gray-2);
    color: color(white);
  }

  &__name {
    p {
      font-size: fontSize(paragraph);
      line-height: 20px;

      span {
        margin-left: 8px;
        color: color(gray-dark);
      }
    }
  }

  &__text {
    font-size: fontSize(paragraph);
    height: 100px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    -webkit-line-clamp: 5;
    -moz-line-clamp: 5;
    -ms-line-clamp: 5;
    line-clamp: 5;
    overflow: hidden;
    margin-bottom: 24px;
  }

  &__likes {
    button {
      cursor: pointer;
      margin-right: 4px;

      img {
        transition-duration: 0.24s;
        transition-property: filter, opacity;
        filter: grayscale(100%);
        opacity: .5;
      }

      &.active {
        img {
          opacity: 1;
          filter: grayscale(0%);
        }
      }

      &:hover,
      &:focus {
        img {
          opacity: 1;
          filter: grayscale(0%);
        }
      }
    }
  }

  &__likes:deep(span:not(.d-icon)) {
    margin-right: 16px;
  }
}
</style>
