import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'

export default async function getTextAsync(input?) {
  const cityStore = useCities()
  const currentCity = computed(() => cityStore.getCity)
  const mainStore = useMainStore()
  const provider = computed(() => mainStore.getDomainConfig)
  const res = await $fetch(`https://${mainStore.domain}/api/providers/${provider.value?.provider?.id || 0}/pages/${input.url}`, {
    method: 'GET',
    params: {
      domain: provider.value?.domain || 'dominternet.ru',
      fias_id: currentCity.value?.fias_id,
      ...input,
      url: undefined,
    },
  })

  return res
}
