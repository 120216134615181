<template>
  <div
    ref="parent"
    data-test="search-by-address-modal"
    class="address-modal white--bg"
  >
    <div class="address-modal__wrap d-flex flex-column flex-grow">
      <div class="address-modal__header d-flex align-center justify-between">
        <d-btn
          data-test="back-button"
          icon
          color="custom"
          class="modal__back"
          @click="$emit('close')"
        >
          <img
            loading="lazy"
            src="@/assets/images/svg/arrow-right.svg"
            alt="arrow"
            style="transform: rotate(180deg)"
          >
        </d-btn>
        <button
          class="address-modal__city relative"
          @click="showDialogCity"
        >
          <img
            v-if="!quiz"
            loading="lazy"
            src="@/assets/images/svg/pin-thin_sec.svg"
            class="address-modal__city-icon"
            alt="pin"
            style="width: 24px; height: 24px;"
          >
          {{
            currentCity?.short_name
              ? currentCity?.short_name + " "
              : ""
          }}{{ currentCity?.name }}
        </button>
      </div>
      <p
        v-if="title"
        class="headline-6 address-modal__title"
      >
        {{ title }}
      </p>
      <form
        autocomplete="off"
        class="address-modal__form d-flex relative"
        name="form"
      >
        <textarea
          id="address-textarea"
          ref="addressRef"
          :value="street"
          autocomplete="honorific-suffix"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          class="address-modal__textarea flex-grow"
          maxlength="100"
          placeholder="Введите улицу и номер дома"
          rows="1"
          style="overflow-y: hidden"
          type="text"
          @blur="focusOff"
          @click="focusOn"
          @input="input"
          @keydown="keyDown"
        />
        <div class="address-modal__actions d-flex align-center">
          <img
            v-show="street"
            loading="lazy"
            src="@/assets/images/svg/chips-close_gray.svg"
            class="address-modal__clear d-flex"
            alt="close"
            @click="clearStreet"
          >
          <div
            v-show="showSelectAddress"
            style="margin-left: 4px"
            class="d-flex address-modal__confirm primary--bg"
            @click="selectAddress"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/arrow-right_white.svg"
              alt="arrow"
            >
          </div>
        </div>
      </form>
      <div
        v-if="
          !quiz
            && configShowMap
            && !loading
            && (!street
              || street.trim() === itemTitle(selectedCity)
              || (!responseList?.address_objects?.length
                && !responseList?.houses.length))
        "
      >
        <button
          class="address-modal__showMap title-5 secondary-dark--text d-flex align-center"
          @click="showMap"
        >
          <span class="address-modal__showMap-pin" />
          Выбрать на карте
        </button>
      </div>
      <div
        v-if="loading"
        class="address-modal__popper relative"
      >
        <div
          v-for="item in 20"
          :key="'load' + item"
          class="w-100 address-modal__btn justify-center d-flex flex-column"
        >
          <VSkeleton
            style="width: 180px; min-height: 25px; max-height: 25px"
            :style="!selectedStreet ? 'max-width: 60%' : 'max-width: 60px'"
          />
          <VSkeleton
            v-if="!selectedStreet"
            style="
                width: 120px;
                min-height: 12px;
                max-height: 12px;
                max-width: 40%;
                margin-top: 4px;
              "
          />
        </div>
      </div>
      <div
        v-else-if="
          !quiz
            && recentSearchShow
            && recentSearchShow.length
            && (!street
              || street.trim() === itemTitle(selectedCity)
              || (!responseList?.address_objects?.length
                && !responseList?.houses?.length))
        "
        class="address-modal__recent"
      >
        <div class="d-flex align-center justify-between">
          <p>Вы искали:</p>
          <button
            class="address-modal__recent-clear gray-dark--text d-flex align-center"
            @click="clearRecentSearch"
          >
            <span class="mask-icon mask-size-lg mask-trash" />
            Удалить все
          </button>
        </div>

        <template v-if="newWindow">
          <a
            v-for="(item, index) in recentSearchShow.filter((el, i) => i < 5)"
            :key="item.houseUrl + index"
            :href="`/personal/address/${item.houseUrl}/`"
            target="_blank"
            class="address-modal__btn justify-center d-flex flex-column"
            @click="chooseRecentHouse(item)"
          >
            <span class="relative d-flex align-center">
              <span class="d-flex address-modal__btn-icon mask-icon mask-size-md mask-search-thin" />
              <span class="address-modal__btn-title secondary-dark--text">{{
                item.name
              }}</span>
            </span>
            <small
              v-if="item.parents_tooltip"
              class="address-modal__btn-subtitle gray-dark--text subhead-6"
            >{{ item.parents_tooltip }}</small>
          </a>
        </template>
        <template v-else>
          <button
            v-for="(item, index) in recentSearchShow.filter((el, i) => i < 5)"
            :key="item.houseUrl + index"
            class="address-modal__btn justify-center d-flex flex-column"
            @click.prevent="chooseRecentHouse(item)"
          >
            <span class="relative d-flex align-center">
              <span class="d-flex address-modal__btn-icon mask-icon mask-size-md mask-search-thin" />
              <span class="address-modal__btn-title secondary-dark--text">{{
                item.name
              }}</span>
            </span>
            <small
              v-if="item.parents_tooltip"
              class="address-modal__btn-subtitle gray-dark--text subhead-6"
            >{{ item.parents_tooltip }}</small>
          </button>
        </template>
      </div>
      <div
        v-else-if="popperShow"
        ref="dragPopper"
        :class="{ 'address-modal__popper-active': popperShow }"
        class="address-modal__popper relative"
        @scroll="drag"
      >
        <template v-if="newWindow">
          <a
            v-for="(item, index) in responseList.houses"
            :key="item.id + index"
            :href="`/personal/address/${item.autogenerated_url}/`"
            target="_blank"
            class="w-100 address-modal__btn justify-center d-flex flex-column"
            @click.stop="chooseHouse(item)"
          >
            <span class="relative d-flex align-center">
              <span class="d-flex address-modal__btn-icon mask-icon mask-size-md mask-search-thin" />
              <span
                class="address-modal__btn-title"
                v-html="addressWithStrong(item.number)"
              />
            </span>
            <small
              v-if="item.parents_tooltip"
              class="address-modal__btn-subtitle gray-dark--text subhead-6"
            >{{ item.parents_tooltip }}</small>
          </a>
        </template>
        <template v-else>
          <button
            v-for="(item, index) in responseList.houses"
            :key="item.id + index"
            class="w-100 address-modal__btn justify-center d-flex flex-column"
            @click.stop.prevent="chooseHouse(item)"
          >
            <span class="relative d-flex align-center">
              <span class="d-flex address-modal__btn-icon mask-icon mask-size-md mask-search-thin" />
              <span
                class="address-modal__btn-title"
                v-html="addressWithStrong(item.number)"
              />
            </span>
            <small
              v-if="item.parents_tooltip"
              class="address-modal__btn-subtitle gray-dark--text subhead-6"
            >{{ item.parents_tooltip }}</small>
          </button>
        </template>
        <button
          v-for="(item, index) in responseList.address_objects"
          :key="item.fias_id + index"
          class="w-100 address-modal__btn justify-center d-flex flex-column"
          @click.stop="chooseAddress(item)"
        >
          <span
            class="address-modal__btn-title"
            v-html="addressWithStrong(itemTitle(item))"
          />
          <small
            v-if="item.parents_tooltip"
            class="address-modal__btn-subtitle gray-dark--text subhead-6"
          >{{ item.parents_tooltip }}</small>
        </button>
        <div
          v-if="loadingMoreSkeleton"
          class="w-100 address-modal__btn justify-center d-flex flex-column"
        >
          <VSkeleton
            style="width: 180px; min-height: 25px; max-height: 25px"
            :style="!selectedStreet ? 'max-width: 60%' : 'max-width: 60px'"
          />
          <VSkeleton
            v-if="!selectedStreet"
            style="
              width: 120px;
              min-height: 12px;
              max-height: 12px;
              max-width: 40%;
              margin-top: 4px;
            "
          />
        </div>
        <div
          v-show="isError"
          class="address-modal__error d-flex flex-column justify-center align-center flex-grow"
          @click.stop
        >
          <p>К сожалению, мы не можем найти этот адрес</p>
          <span>Попробуйте изменить написание</span>
        </div>
        <p
          v-if="
            !isError
              && (enableInfinite === false
                || (selectedStreet
                  ? responseList.houses.length >= totalItems
                  : responseList.address_objects.length >= totalItems))
          "
          class="text-center"
          style="padding: 20px 0"
          @click.stop
        >
          Продолжите ввод, если не нашли в этом списке
        </p>
      </div>
    </div>

    <LazyVDialog
      v-model="dialogCity"
      :fullscreen="!disabledPortal"
      :width="disabledPortal ? 'auto' : ''"
    >
      <LazyModalCityCheck
        @close="dialogCity = false"
        @changed-city="changedCity"
      />
    </LazyVDialog>
  </div>
</template>

<script lang="ts" setup>
import type { BaseAddressObject, House } from '~/api'
import { useCities } from '~/store/cites/cities.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { debounce } from '~/helpers'
import { useMainStore } from '~/store/main/main.store'
import { recentSearchFn } from '~/composible/recentSearch'
import { scrollToBlock } from '~/composible/scrollToBlock'
import { getAddresLocation } from '~/composible/getAddresLoc'

const props = defineProps({
  target: String,
  title: String,
  newWindow: {
    type: Boolean,
  },
  quiz: {
    type: Boolean,
  },
  woFocus: {
    type: Boolean,
  },
  redirect: {
    type: Boolean,
    default: true,
  },
  reOpenCity: Boolean,
})
const emit = defineEmits(['close', 'reOpen'])

const $route = useRoute()
const $router = useRouter()
const ctx = useNuxtApp()
const mainStore = useMainStore()
const cityStore = useCities()
const tariffsStore = useTariffs()
const oldClientCookie = useCookie('oldClient', { maxAge: 60 * 60 * 24 })
const providerClientCookie = useCookie('providerClient', { maxAge: 60 * 60 * 24 })
const parent = ref()
const street = ref('')
const addressRef = ref()
const dialogCity = ref(false)
const selectedHouse = ref<House>()
const selectedStreet = ref<BaseAddressObject>()
const selectedCity = ref()
const recentSearch = computed(() => tariffsStore.getRecentSearch)
const configShowMap = computed(() => mainStore.getConfigShowMap)
const getAddressText = computed(() => cityStore.getAddressText)
const disabledPortal = computed(() => mainStore.disabledPortal)
const recentSearchShow = computed(() =>
  recentSearch.value.filter(el => el.houseUrl !== houseUrl.value),
)
const showDialogCity = () => {
  if (props.reOpenCity) {
    emit('reOpen')
    return
  }
  if (props.redirect)
    ctx.$event('cityDialog', { redirect: true, label: 'modal-address' })
  else dialogCity.value = true
}
const domain = computed(() => mainStore.getDomain)
const enableInfinite = ref<boolean | undefined>()
const houseEmpty = ref(false)
const totalItems = ref<number | undefined>()
const popperShow = ref(false)
const eventInput = ref(true)
const loading = ref(false)
const loadingValidate = ref()
const loadingMore = ref(false)
const loadingMoreSkeleton = ref(false)
const dragPopper = ref()
const isError = ref(false)
const focused = ref(false)
const currentCity = computed(() => cityStore.getCity)
const currentStreet = computed(() => cityStore.getDataStreet)
const houseUrl = computed(() => cityStore.getHouseUrl)
const isSearchAddressPage = computed(
  () =>
    $route.name === 'personal-address-slug'
    || $route.name === 'city-streets-street'
    || $route.name === 'city-address-slug',
)
const partner = computed(() => mainStore.getDomainConfig)
const log = ref([])
const { clearRecentSearch, addRecentSearch } = recentSearchFn()

const responseList = ref({
  address_objects: [] as BaseAddressObject[],
  houses: [] as House[],
})

const showMap = () => {
  ctx.$event('mapDialog', {
    label: 'address-modal',
    redirect: !$route.path.includes('/complete/'),
  })
  emit('close')
}
const changedCity = () => {
  dialogCity.value = false
  if (addressRef.value) addressRef.value.focus()
}

const clearAddress = () => {
  let res

  if ($route.name === 'city-not-available') {
    $router.push({ path: `/${currentCity.value?.url}/` })
    return
  }
  else if ($route.name === 'city-streets-street') {
    street.value = ''
    return
  }

  const redirect = isSearchAddressPage.value

  nextTick(async () => {
    try {
      res = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${currentCity.value?.fias_id}/ancestors/`, {
        method: 'GET',

        params: {
          include_self: true,
          morph: true,
        },
      })
    }
    catch {}
    cityStore.$patch({
      addressAncestors: res,
    })
    cityStore.house = {}
    tariffsStore.passThroughProviderId = null

    if (redirect) {
      $router.push({ path: `/${currentCity.value?.url}/` })
    }

    const coockieAddress = useCookie('currentAddress')
    coockieAddress.value = ''
  })
}

const searchAddress = (queryString: string) => {
  if (!(selectedCity.value && queryString.includes(selectedCity.value.name))) {
    selectedCity.value = undefined
  }
  if (
    !(selectedStreet.value && queryString.includes(selectedStreet.value.name))
  ) {
    houseEmpty.value = false
    selectedStreet.value = undefined
    selectedHouse.value = undefined
  }

  if (
    !(selectedHouse.value && queryString.includes(selectedHouse.value.number))
  ) {
    selectedHouse.value = undefined
  }

  if (street.value)
    getSuggestionsMulti(
      queryString
        .replace(itemTitle(selectedCity.value), '')
        .replace(itemTitle(selectedStreet.value), '')
        .trim(),
      (cb) => {
        responseList.value
          = typeof cb === 'object' && !Array.isArray(cb) && cb !== null
            ? cb
            : {
                address_objects: [],
                houses: [],
              }
      },
    )
}

const getSuggestionsMulti = async (
  queryString: string,
  cb: (arg) => void,
  offset?: number,
) => {
  loadingMore.value = true
  try {
    const validate = Math.random()
    loadingValidate.value = validate
    const response = await $fetch(`https://${mainStore.domain}/api/suggestions/multi/`, {
      method: 'GET',

      params: {
        query: queryString,
        tooltips_always: true,
        site_city_fias_id: currentCity.value?.fias_id,
        street_fias_id: selectedStreet.value?.fias_id,
        move_house_street_tooltip: true,
        limit: 20,
        offset,
      },
    })
    log.value.push({
      req: queryString,
      resp: response,
    })
    if (loadingValidate.value === validate) {
      enableInfinite.value = response.can_scroll
      totalItems.value = response.total_items
      const block = document.getElementsByClassName(
        'address-modal__popper',
      )?.[0]
      if (block && !offset) block.scrollTo(0, 0)
      cb(response)
      popperShow.value = true
      loading.value = false
      loadingMoreSkeleton.value = false
      loadingMore.value = false
      if (response?.address_objects?.length || response?.houses?.length)
        isError.value = false
      else isError.value = true
    }
  }
  catch (e) {
    isError.value = true
    loadingMore.value = false
    cb([])
  }
}

const debounceSearch = debounce(() => {
  searchAddress(street.value)
}, 350)

const textareaHeight = () => {
  addressRef.value.style.height = 'auto'
  addressRef.value.style.height
    = (+addressRef.value.scrollHeight > 72
      ? 72
      : +addressRef.value.scrollHeight) + 'px'
  addressRef.value.style.overflowY
    = +addressRef.value.scrollHeight > 72 ? 'scroll' : 'hidden'
}

const input = (event) => {
  if (eventInput.value) {
    eventInput.value = false
    ctx.$event('gtmSendEvent', {
      event: 'trackEvent',
      category: 'address',
      action: 'typing',
      label: '',
    })
  }
  loadingValidate.value = Math.random()
  event.target.composing = false
  street.value = event.target.value
  nextTick(() => {
    if (street.value) loading.value = true
    else {
      loading.value = false
      popperShow.value = false
    }
    debounceSearch()
    textareaHeight()
  })
}

const keyDown = (e) => {
  if (e.keyCode === 13) e.preventDefault()
}

const showSelectAddress = computed(() => {
  return (
    houseUrl.value
    && street.value.toLowerCase().trim()
    === getAddressText.value.houseAddress.toLowerCase().trim()
  )
})

const selectAddress = () => {
  $router.push({
    path: `/personal/address/${houseUrl.value}/`,
  })
  emit('close')
}

const clearStreet = () => {
  street.value = ''
  houseEmpty.value = false
  popperShow.value = false
  selectedStreet.value = undefined
  selectedCity.value = undefined
  selectedHouse.value = undefined
  nextTick(() => {
    clearAddress()
    if (addressRef.value) addressRef.value.focus()
    textareaHeight()
  })
}

const chooseAddress = (st) => {
  const oldCitySlug = currentCity.value?.url
  const redirect = isSearchAddressPage.value
  if (st.level === 'city' || st.fias_id === currentCity.value?.fias_id) {
    selectedCity.value = st
    nextTick(async () => {
      street.value = itemTitle(st)
      selectedHouse.value = undefined
      selectedStreet.value = undefined
      focusHome()
      let res
      try {
        res = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${st.fias_id}/ancestors/`, {
          method: 'GET',

          params: {
            include_self: true,
            morph: true,
          },
        })
      }
      catch (e) {
      }
      finally {
      }
      cityStore.$patch({
        addressAncestors: res,
      })
      cityStore.house = {}

      tariffsStore.passThroughProviderId = null

      const coockieCity = useCookie('currentCity')
      const coockieAddress = useCookie('currentAddress')
      coockieCity.value = selectedCity.value.fias_id
      coockieAddress.value = ''

      if (redirect) {
        $router.replace(`/${st.url}/providers-to-address/`)
      }
      else {
        $router.replace($route.path.replace(oldCitySlug!, st.url!))
      }
    })
  }
  else {
    selectedStreet.value = st

    nextTick(() => {
      street.value
        = (selectedCity.value ? itemTitle(selectedCity.value) + ' ' : '')
        + itemTitle(st)
      selectedHouse.value = undefined
      searchAddress(street.value)
      if (!props.woFocus) focusHome()
      loading.value = true
    })
  }
}

const chooseHouse = (st) => {
  selectedHouse.value = st
  nextTick(() => {
    street.value = st.parents_tooltip
      ? st.parents_tooltip + ' ' + st.number
      : (selectedCity.value ? itemTitle(selectedCity.value) + ' ' : '')
        + itemTitle(selectedStreet.value)
        + ' '
        + st.number
    houseEmpty.value = false
  })
  changeAddress()
  if (props.redirect) emit('close')
}
const chooseRecentHouse = (st) => {
  selectedHouse.value = st.selectedHouse
  nextTick(() => {
    street.value = st.name
    houseEmpty.value = false
  })
  changeAddress()
  if (props.redirect) emit('close')
}
const changeAddress = () => {
  const oldCitySlug = currentCity.value?.url
  ctx.$event('gtmSendEvent', {
    event: 'trackEvent',
    category: 'address',
    action: 'confirmed',
    label: '',
  })
  nextTick(async () => {
    log.value = []
    let res
    let loc
    try {
      const { getAddresLoc } = getAddresLocation()

      const promiseLoc = async () => {
        loc = await getAddresLoc({
          url: selectedHouse.value!.autogenerated_url,
          houseId: selectedHouse.value!.id,
          houseFiasId: selectedHouse.value!.dadata?.fias_id,
          streetFiasId:
            selectedHouse.value!.dadata?.house
            && selectedHouse.value!.dadata?.parent_fias_id
              ? selectedHouse.value!.dadata?.parent_fias_id
              : undefined,
          houseNumber:
            selectedHouse.value!.dadata?.house
            && selectedHouse.value!.dadata?.parent_fias_id
              ? selectedHouse.value!.dadata?.house
              + (selectedHouse.value!.dadata?.block_type
                ? ' ' + selectedHouse.value!.dadata?.block_type
                : '')
              + (selectedHouse.value!.dadata?.block
                ? ' ' + selectedHouse.value!.dadata?.block
                : '')
              : undefined,
        })
      }
      const promiseRes = async () => {
        if (selectedHouse.value!.dadata?.parent_fias_id) {
          res = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${selectedHouse.value!.dadata?.parent_fias_id}/ancestors/`, {
            method: 'GET',

            params: {
              include_self: true,
              morph: true,
            },
          })
        }
      }

      if (props.redirect && !partner.value && !props.newWindow) {
        mainStore.$patch({
          showLoader: true,
        })
      }
      await promiseLoc()
      await promiseRes()
    }
    catch (e) {
      ctx.$sentryCaptureMessage(e)
      // result = []
      // showLoader: false
    }
    finally {
      selectedCity.value = res?.address_objects?.find(
        el => el.level === 'city',
      )
      if (!selectedCity.value)
        selectedCity.value = res?.address_objects?.find(
          el => el.level === 'street',
        )
      else
        selectedStreet.value = res?.address_objects?.find(
          el => el.level === 'street',
        )
      cityStore.$patch({
        addressAncestors: res,
      })
      mainStore.$patch({
        wrongAddress: false,
      })

      addRecentSearch({
        name: street.value,
        houseUrl: loc.autoGeneratedUrl,
        parents_tooltip:
          (selectedCity.value?.short_name
            ? selectedCity.value.short_name + ' '
            : '') + selectedCity.value?.name,
        selectedHouse: selectedHouse.value,
      })

      const coockieAddress = useCookie('currentAddress')

      coockieAddress.value = selectedHouse.value!.autogenerated_url
      mainStore.$patch({
        oldClient: false,
        providerClient: undefined,
      })
      oldClientCookie.value = null
      providerClientCookie.value = null

      if (selectedCity.value) {
        const coockieCity = useCookie('currentCity')
        coockieCity.value = selectedCity.value.fias_id
      }

      if (
        props.redirect
        && (!partner.value || $route.name === 'personal-address-slug')
      ) {
        if (!props.newWindow) {
          $router.push({
            path: `/personal/address/${loc.autoGeneratedUrl}/`,
          })
        }
      }
      else if (!partner.value && props.redirect && selectedCity.value) {
        $router.replace({
          path: $route.fullPath.replace(oldCitySlug, currentCity.value?.url),
        })
      }
      else if (!partner.value) {
        if (!props.redirect) emit('close')
      }
      else {
        if ($route.name === 'index') {
          $router.push({ path: `/${currentCity.value?.url}/` })
        }
        else if (selectedCity.value) {
          $router.replace({
            path: $route.fullPath.replace(oldCitySlug, currentCity.value?.url),
          })
        }
        if (partner.value.provider.slug !== 'mts') {
          const element = document.getElementsByClassName('layout')[0]
          scrollToBlock(element, 500)
        }
        if (!props.redirect) emit('close')
      }
    }
  })
}

const itemTitle = (city: BaseAddressObject | undefined) => {
  return city
    ? `${city.short_name ? city.short_name + ' ' : ''}${city.name}`
    : ''
}

const addressWithStrong = (title: string) => {
  if (!street.value) return title
  const words = street.value.trim().split(/[^0-9а-яА-ЯёЁ]+/gmi).filter(Boolean).sort((a, b) => b.length - a.length)
  let newTitle = title
  const regexList = words.map(word => new RegExp(`(?!<strong[^>]*?>)(${word})(?![^<]*?</strong>)`, 'giu'))

  regexList.forEach((regex) => {
    newTitle = newTitle.replace(regex, match => `<strong class='cl-pr--t'>${match}</strong>`)
  })
  return newTitle
}

const focusHome = () => {
  nextTick(() => {
    street.value += ' '
    if (addressRef.value) addressRef.value.focus()
    responseList.value = {
      address_objects: [],
      houses: [],
    }
  })
}

const drag = debounce(() => {
  if (
    !loadingMore.value
    && enableInfinite.value
    && (selectedStreet.value
      ? responseList.value?.houses?.length < totalItems.value
      : responseList.value?.address_objects?.length < totalItems.value)
  ) {
    if (dragPopper.value) {
      if (dragPopper.value.scrollHeight > dragPopper.value.clientHeight) {
        const scrolledToBottom
          = dragPopper.value.scrollHeight - dragPopper.value.clientHeight
          <= dragPopper.value.scrollTop + 300
        if (scrolledToBottom) loadMore()
      }
    }
  }
}, 50)

const loadMore = () => {
  if (street.value) {
    loadingMoreSkeleton.value = true
    getSuggestionsMulti(
      street.value
        .replace(itemTitle(selectedCity.value), '')
        .replace(itemTitle(selectedStreet.value), '')
        .trim(),
      addMore,
      selectedStreet.value
        ? responseList.value?.houses?.length
        : responseList.value?.address_objects?.length,
    )
  }
}
const addMore = (val) => {
  responseList.value.houses.push(...val.houses)
  responseList.value.address_objects.push(...val.address_objects)
}

const focusOn = () => {
  focused.value = true
}
const focusOff = () => {
  if (!(document.activeElement?.localName === 'input')) focused.value = false
}

onBeforeMount(() => {
  nextTick(() => {
    if (!street.value && houseUrl.value) {
      street.value = getAddressText.value.houseAddress
    }
    else if (!street.value && currentStreet.value?.url) {
      chooseAddress(currentStreet.value)
    }
  })
})
const scrollTopFn = () => {
  parent.value.scrollTo(0, 0)
  window.removeEventListener('resize', scrollTopFn)
}
onMounted(() => {
  const inputAddress = document.getElementById('address-textarea')
  if (!props.woFocus) {
    if (inputAddress) nextTick(() => inputAddress.focus())
  }
  nextTick(() => parent.value.scrollTo(0, 0))
  if (ctx.$device.isAndroid) {
    window.addEventListener('resize', scrollTopFn)
  }
})

onBeforeUnmount(async () => {
  if (ctx.$device.isAndroid) {
    window.removeEventListener('resize', scrollTopFn)
  }
  if (log.value.length) {
    await $fetch(`https://${mainStore.domain}/api/core/logs/`, {
      method: 'POST',

      body: {
        name: 'address_fail',
        at_url: 'https://' + domain.value + $route.fullPath,
        level: 'DEBUG',
        // "data": JSON.stringify(log.value)
        data: log.value,
      },
    })
  }
})

defineExpose({ chooseAddress })

watch(
  () => currentCity.value?.fias_id,
  () => {
    if (!selectedCity.value) {
      street.value = ''
    }
  },
)
</script>

<style scoped lang="scss">
.address-modal {
  width: 630px;
  max-width: 100%;
  height: 561px;
  max-height: 100vh;
  display: block;
  padding: 12px 24px 0;
  border-radius: 16px;
  @media (max-width: getBreakpoint(tablet)) {
    border-radius: 0;
  }

  .mask-trash {
    mask-image: url('@/assets/images/svg/trash.svg');
  }
  .mask-search-thin {
    mask-image: url('@/assets/images/svg/search-thin.svg');
  }

  &__wrap {
    max-height: 100%;
  }

  &__header {
    margin-bottom: 16px;
  }

  &__showMap {
    margin-top: -8px;
    transition: color 0.24s;
    margin-left: 16px;
    &:hover {
      color: color(primary);
    }
    &-pin {
      margin-right: 4px;
      width: 24px;
      height: 24px;
      mask-image: url('@/assets/images/svg/location.svg');
      background-color: currentColor;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: 24px;
    }
  }

  &__back {
    padding: 0;
    width: 32px;
    height: 32px;
    @media (max-width: getBreakpoint(mobile-lg)) {
      width: 28px;
      height: 28px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      width: 24px;
      height: 24px;
    }
    &:deep(svg) {
      width: 32px;
      height: 32px;
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 28px;
        height: 28px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__textarea {
    padding: 12px 48px 12px 28px;
    border-radius: 24px;
    resize: none;
    border: 1px solid color(gray-2);
    background: color(gray-light-3);
    transition: border-color 0.3s;
    line-height: 1.5;
    min-height: 48px;

    &:focus, &:hover {
      border-color: color(secondary-dark);
    }

    &::placeholder {
      color: color(gray-dark);
    }
  }
  &__actions {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__clear {
    margin-right: 8px;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  &__confirm {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 4px;
    @media (max-width: getBreakpoint(mobile-lg)) {
      width: 34px;
      height: 34px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      width: 32px;
      height: 32px;
    }
    img {
      width: 28px;
      height: 28px;
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 26px;
        height: 26px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__title {
    margin-bottom: 24px;
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-bottom: 16px;
    }
  }

  &__form {
    margin-bottom: 20px;
  }
  &__recent {
    margin-top: 24px;
    .address-modal__btn {
      padding-left: 32px;
      border-bottom: none;
      min-height: 47px;
    }

    &-clear {
      transition: color 0.24s;
      font-size: fontSize(paragraph);
      @media (max-width: getBreakpoint(tablet)) {
        font-size: fontSize(label);
      }
      .d-icon {
        margin-right: 8px;
        transition: color 0.24s;
      }
      &:hover {
        color: color(black);
        .d-icon {
          color: color(black);
        }
      }
    }
  }

  &__city {
    margin-left: 28px;
    color: color(secondary-dark);
    transition: color 0.3s;

    &-icon {
      position: absolute;
      left: -28px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      mask-image: url('@/assets/images/svg/location.svg');
      background-color: currentColor;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: 24px;
    }

    &:hover {
      color: color(primary);
    }

    & ~ & {
      margin-top: 12px;
    }
  }

  &__popper {
    padding-left: 28px;
    flex: 1 1 auto;
    overflow-y: auto;
    -webkit-user-drag: none;
  }

  &__btn {
    border-bottom: 1px solid color(gray-light);
    min-height: 57px;
    color: color(black);
    cursor: pointer;
    .secondary-dark--text {
      transition: color 0.24s;
    }
    &:hover .secondary-dark--text {
      color: color(primary);
    }

    &-title:deep(strong) {
      color: color(primary);
      font-weight: 400;
    }

    &-icon {
      position: absolute;
      left: -28px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 1px;
    bottom: 0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    &:deep(svg) {
      will-change: transform;

      path:not(:nth-child(3)) {
        transform-origin: center;
        animation-name: path-animation;
        animation-iteration-count: infinite;
        // animation-direction: alternate;
        animation-duration: 1s;
        transform: scale(0);
      }

      path:nth-child(1) {
        animation-delay: 0.5s;
      }
    }
  }

  &__error {
    p {
      margin-bottom: 8px;
    }

    span {
      font-size: fontSize(paragraph);
    }
  }

  @media (max-width: getBreakpoint(tablet)) {
    padding: 12px 16px 0;
  }
  @media (max-width: getBreakpoint(mobile-lg)) {
    &__city {
      font-size: fontSize(paragraph);
    }
  }
  @media (max-width: getBreakpoint(mobile-md)) {
    &__textarea {
      padding: 8px 40px 8px 24px;
      border-radius: 20px;
      font-size: fontSize(paragraph);
      min-height: 40px;
    }
    &__clear {
      right: 11px;
    }
    &__city {
      margin-left: 24px;
    }
    &__popper {
      padding-left: 24px;
    }
    &__btn {
      min-height: 53px;

      &-title {
        font-size: fontSize(paragraph);
      }

      &-subtitle {
        font-size: fontSize(small);
      }

      &-icon {
        left: -24px;
      }
    }
  }
}
</style>
