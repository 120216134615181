import { useMainStore } from '~/store/main/main.store'

export default async function getCallsGeneral(input) {
  const mainStore = useMainStore()
  return await $fetch(`https://${mainStore.domain}/api/calls/general/`, {
    method: 'GET',
    params: {
      ...input,
    },
  })
}
